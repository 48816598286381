<template>
    <div class="report-agent">
        <div class="page-header">
            <StackRouterHeaderBar />
            <div class="title" v-html="$translate('REPORT_AGENTS') + '하기'" />
        </div>
        <div class="body">
            <div class="select-agent-reason m-b-32">
                <div class="selection m-b-32">
                    <div class="title" v-html="$translate('SELECT_AGENT')" />
                    <OptionSelectionBox
                        :placeholder="$translate('SELECT_AGENT_PLACEHOLDER')"
                        :option="selectedAgent.name"
                        @click.native="onClickSelectionBox('agent')"
                    />
                </div>
                <div class="selection">
                    <div class="title" v-html="$translate('SELECT_REPORT_REASON')" />
                    <OptionSelectionBox
                        :placeholder="$translate('SELECT_REPORT_REASON_PLACEHOLDER')"
                        :option="selectedReason.name"
                        @click.native="onClickSelectionBox('reason')"
                    />
                </div>
            </div>
            <div class="detail-reason m-b-32">
                <div class="title">구체적 사유 작성<span>(선택)</span></div>
                <TextareaWithX :placeholder="'구체적인 신고 내용을 작성해주세요'" v-model="detailReason" />
            </div>
            <div class="photos m-b-36">
                <div class="title">첨부 자료<span>(선택)</span></div>
                <div class="photos-wrapper">
                    <div
                        class="photo flex-wrap"
                        v-for="(photo, idx) in photos"
                        :key="photo.key"
                        @click="onClickPhoto(idx)"
                    >
                        <i v-if="!photo.url" class="material-icons">add</i>
                        <img v-else :src="photo.url" />
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-wrapper">
            <button
                class="btn btn-primary btn-submit"
                v-html="$translate('SUBMIT')"
                :disabled="!enabled"
                @click="onClickSubmitButton"
            />
        </div>
        <input
            ref="imageUploader"
            type="file"
            class="image display-none"
            @change="onChangeImage($event)"
            accept="image/*"
        />
    </div>
</template>

<script>
import agentService from '@/services/agent'
import centerService from '@/services/center'

export default {
    name: 'ReportAgentPage',
    data: () => ({
        reasons: [],
        agents: [],
        selectedReason: {},
        selectedAgent: {},
        photos: [],
        selectedPhotoIndex: null,
        detailReason: '',
    }),
    computed: {
        enabled() {
            return !!this.selectedReason.name && !!this.selectedAgent.name
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            try {
                this.photos = this.initPhotos()
                const { reasons } = await centerService.loadReason()
                this.agents = await agentService.loadReportTargets()
                this.reasons = reasons.filter(reason => {
                    const { role, rtype } = reason
                    return role === 'user' && rtype === 'agent'
                })
            } catch (e) {
                this.$toats.error(e.data)
                this.$router.go(-1)
            }
        },
        initPhotos() {
            return Array(3)
                .fill({})
                .map((photo, idx) => {
                    return {
                        key: idx + 1,
                        url: '',
                        blob: null,
                        name: '',
                    }
                })
        },
        onClickSelectionBox(optionType) {
            let list = []
            let title = ''
            if (optionType === 'agent') {
                title = '주선자 선택'
                list = this.agents.map(({ id, name, nickname }) => {
                    const newAgent = {
                        id,
                        name: `${name}(${nickname})`,
                    }

                    return newAgent
                })
            } else if (optionType === 'reason') {
                title = '신고 사유 선택'
                list = this.reasons
            }

            this.$modal
                .custom({
                    component: 'ModalSingleOptionSelector',
                    options: {
                        title,
                        optionType,
                        list,
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    },
                })
                .then(option => {
                    if (!option) return
                    if (optionType === 'agent') {
                        this.selectedAgent = option
                    } else if (optionType === 'reason') {
                        this.selectedReason = option
                    }
                })
        },
        onChangeImage(event) {
            const file = event.target.files[0]

            const selectedPhoto = this.photos[this.selectedPhotoIndex]
            selectedPhoto.name = file.name

            this.$refs.imageUploader.value = ''

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile: file,
                        allowFreeAspectRatio: false,
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return
                    selectedPhoto.url = URL.createObjectURL(croppedFile)
                    selectedPhoto.blob = croppedFile
                    this.selectedPhotoIndex = null
                })
        },
        onClickPhoto(index) {
            this.selectedPhotoIndex = index
            this.$refs.imageUploader.click()
        },
        async onClickSubmitButton() {
            try {
                this.$loading(true)
                const payload = this.populatePayload()
                const { msg } = await agentService.reportAgent.create(this.selectedAgent.id, payload)
                this.$toast.success(msg)
                setTimeout(() => this.$stackRouter.pop(), 500)
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        populatePayload() {
            const formData = new FormData()
            formData.append('reason_id', this.selectedReason.id)

            if (this.detailReason.length > 0) {
                formData.append('detail', this.detailReason)
            }

            if (this.photos.some(photo => photo.blob)) {
                const filteredPhotos = this.photos.filter(photo => photo.blob)
                filteredPhotos.forEach((photo, index) => {
                    formData.append(`photo_${index + 1}`, photo.blob, photo.name)
                })
            }

            return formData
        },
    },
}
</script>
